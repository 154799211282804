import { useState } from "react";
import moment from "moment";
import { DateSelect, Button, ConfirmationDialog } from "../../common";
import { JobsApi } from "../../api";

export const EndOfMonth = ({ invoicesSelected, setInvoicesSelected }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(moment().format("DD/MM/YYYY"));
  const createEdInvoiceMutation = JobsApi.useCreateEdInvoice();
  const updateEdInvoiceMutation = JobsApi.useUpdateEdInvoice();

  const endOfMouth = () => {
    if (!invoicesSelected) {
      return;
    }

    invoicesSelected.map(async (row) => {
      try {
        updateEdInvoiceMutation.mutateAsync({ id: row.id, status: "Approved" });
      } catch (error) {
        console.log("ERROR UPDATING INVOICE", error);
      }

      if (row.complete_percent !== 100) {
        const duplicatedInvoice = row;
        delete duplicatedInvoice.id;
        delete duplicatedInvoice.jobs;
        try {
          await createEdInvoiceMutation.mutateAsync(duplicatedInvoice);
          createEdInvoiceMutation.isSuccess = true;
        } catch (err) {
          console.log("ERROR DUPLICATING INVOICE", err);
        }
      }
    });

    setInvoicesSelected([]);
  };

  const handleInputChange = (id, val) => {
    setDate(moment(val).format("DD/MM/YYYY"));
  };

  return (
    <ConfirmationDialog
      isDone={createEdInvoiceMutation.isSuccess}
      icon="info"
      title="End Of Month"
      body="This action will create a new One Off Charge Invoices And Aproved selected."
      triggerButton={
        <Button
          label="End Of The Month"
          // style={{ backgroundColor: "#0078d4", borderRadius:"4px" }}
          icon="submit"
          className="p-button-raised p-3 flex align-items-center justify-content-center"
        >
          End Of The Month
        </Button>
      }
      confirmButton={
        <Button
          isLoading={isLoading}
          variant="approve"
          onClick={async (e) => {
            setIsLoading(true);
            endOfMouth();
            setIsLoading(false);
          }}
        >
          End Of The Month
        </Button>
      }
    >
      {/*

            <div className="flex">

                <DateSelect
                    title="Date End Of Mouth"
                    id="date"
                    value={date}
                    onChange={handleInputChange}
                />

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        */}
    </ConfirmationDialog>
  );
};
