import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../supabase";
import { fetchJob } from "../Jobs/getJob";

import { useNotificationStore } from "../../store/notifications";

export async function updateVisit({ visit, visitId, leader }) {
  const { data, error } = await supabase.from("visits").update(visit).match({ id: visitId });

  if (error) {
    throw new Error(error.message);
  }

  let staffName = "";
  if (leader) {
    const staff = await supabase.from("staff").select("*").eq("id", leader);

    staffName = staff?.data?.[0]?.staff_name || "";
  }
  let client = "";
  if (data[0]?.job_id) {
    const { data: jobData, error: jobError } = await supabase
      .from("jobs")
      .select("client:client_id(id, client_name)")
      .eq("id", data[0]?.job_id)
      .single();

    if (jobError) {
      throw new Error(jobError.message);
    }
    client = jobData?.client || "";
  }
  await updateAppenateVisits(data, staffName, client);

  return data;
}

export const useUpdateVisit = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation((visit) => updateVisit(visit), {
    onSuccess: (data) => {
      queryClient.refetchQueries("visits");

      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: `Successfully updated visit.`,
      });
    },

    onError: (err) => {
      addNotification({
        isSuccess: false,
        heading: "Failed updating visit",
        content: err?.message,
      });
    },
    mutationFn: updateVisit,
  });
};

async function updateAppenateVisits(data, staffName, client) {
  /*
    ROWS:
    id - Job Id - Job Display - date - vehicle Ids - vehicle display - staff ids - staff display - team leader id - task ids - task display - notes - status - risk factor - type -- additional tools - safety equipment - specialized PPE
  */
  const visitPayload = [];
  const job = await fetchJob(data[0].job_id);
  data.map((visit) =>
    visitPayload.push([
      visit.id, // id
      visit.job_id, // job Id
      `${visit.job_id + 1000} - ${client?.client_name} - ${job?.site} - ${visit?.type}`, // job display
      visit.date, // date
      "", // vehicle Ids
      "", // vehicle display
      visit?.staff_ids?.join(",") || "",
      visit?.staff_labels?.join(",") || "",
      visit?.team_leader_id || "",
      staffName || "",
      visit?.task_ids?.join(",") || "",
      visit?.task_labels?.join(",") || "",
      visit?.notes || "",
      visit?.visit_status || "",
      "",
      visit?.risk || "",
      visit?.type || "",
      "",
      "",
      "",
      "",
      "",
      "",
      visit?.vehicle_ids?.join(",") || "" || "",
      visit?.vehicle_labels?.join(",") || "" || "",
      job.site || "",
    ]),
  );
  return axios.post("https://tas-scaffm8-521047fce122.herokuapp.com/api/data-sync", {
    id: "f0dc7dd2-d10b-4700-9171-aeda00355920",
    data: visitPayload,
  });
}
