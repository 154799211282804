import { useState } from "react";
import moment from "moment";
import { DateSelect, Button, ConfirmationDialog } from "../../common";
import { useNotificationStore } from "../../store/notifications";

export const ApproveInvoices = ({
  invoicesSelected,
  setInvoicesSelected,
  setStandardCompleteDate,
}) => {
  const { addNotification } = useNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [date, setDate] = useState(moment().format("DD/MM/YYYY"));

  const approveInvoicesProcess = async () => {
    if (!invoicesSelected) {
      return;
    }

    setTimeout(async () => {
      const uniqueJobs = Array.from(new Set(invoicesSelected.map((item) => item.job_id)));
      await uniqueJobs.map(async (jobID) => {
        const invoices = invoicesSelected.filter((e) => e.job_id === jobID);
        await fetch("https://tas-scaffm8-521047fce122.herokuapp.com/api/checkcontact", {
          method: "POST",
          body: JSON.stringify({
            client:
              invoices[0]?.jobs?.billing_address === "No"
                ? invoices[0]?.jobs?.namesNewClient
                : invoices[0]?.jobs?.clients?.client_name,
            email:
              invoices[0]?.jobs?.billing_address === "No"
                ? invoices[0]?.jobs?.newContactEmail
                : invoices[0]?.jobs?.clients?.email,
            branding: invoices[0]?.jobs?.branding,
          }),
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
          .catch((error) => console.error("Error:", error))
          .then((response) => console.log("Success:", response));

        // send items to xero to create new invoce
        const hasNullTotal = invoices.some(
          (obj) => obj.InvoiceType === "Weekly Charge" && !obj.total,
        );
        const hasNullEDTotal = invoices.some(
          (obj) => obj.InvoiceType === "One Off Charge" && !obj.ed_total,
        );
        if (!hasNullTotal && !hasNullEDTotal) {
          fetch("https://tas-scaffm8-521047fce122.herokuapp.com/invoice", {
            method: "POST", // or 'PUT'
            body: JSON.stringify({
              type: "weekly_hire_invoices",
              endMouth: true,
              data: invoices,
              date,
              duplicate: false,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((res) => res.json())
            .catch((error) =>
              addNotification({
                isSuccess: false,
                heading: "Error!",
                content: `An error has occurred. The invoice was not created. Network Error With Xero.`,
              }),
            )
            .then((response) => {
              let isSuccess = true;
              let content = "Invoice created successfully";
              if (response.err) {
                isSuccess = false;
                content = "Error!";
              }
              const heading = response.message;
              addNotification({
                isSuccess,
                heading,
                content,
              });
            });
          setCompleted(true);
          setInvoicesSelected([]);
        } else {
          addNotification({
            isSuccess: false,
            heading: "An error has occurred. The invoice was not created.",
            content: "Error: Invoice must have a total value",
          });
        }
      });

      setTimeout(() => {
        setStandardCompleteDate(moment().format("DD/MM/YYYY"));
        setCompleted(true);
        setInvoicesSelected([]);
      }, uniqueJobs.length * 5000);
    }, 1000);
  };

  const handleInputChange = (id, val) => {
    setDate(moment(val).format("DD/MM/YYYY"));
    setStandardCompleteDate(moment(val).format("DD/MM/YYYY"));
  };

  return (
    <ConfirmationDialog
      isDone={completed}
      icon="info"
      title="Approve Invoices"
      body="This action will send to xero the invoices selected. Select A Date for the process"
      triggerButton={
        <Button
          label="Approve Invoices"
          style={{ backgroundColor: "#0078d4", borderRadius: "4px" }}
          icon="submit"
          className="p-button-raised p-3 flex align-items-center justify-content-center"
        >
          Approve Invoices
        </Button>
      }
      confirmButton={
        <Button
          isLoading={isLoading}
          variant="approve"
          onClick={async (e) => {
            setIsLoading(true);
            approveInvoicesProcess();
            setIsLoading(false);
          }}
        >
          Approve Invoices
        </Button>
      }
    >
      <div className="flex">
        <DateSelect title="Approve Invoices" id="date" value={date} onChange={handleInputChange} />

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </ConfirmationDialog>
  );
};
