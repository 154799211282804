export const quoteColumns = [
  {
    id: "description",
    heading: "Item Description",
  },
  {
    id: "erect",
    heading: "Erect & Dismantle (p/u)",
  },
  {
    id: "fee",
    heading: "Weekly Hire Fee",
  },
];


export const zoneTotalsColumns = [
  {
    id: "zone",
    heading: "Zone",
  },
  {
    id: "label",
    heading: "Zone Label",
  },
  {
    id: "erectZone",
    heading: "Erect & Dismantle Total",
  },
  {
    id: "hireTotal",
    heading: "Weekly Hire Total",
  },
  {
    id: "zoneTotal",
    heading: "Total Cost",
  },
];

export const additionalItemsColumns = [
  {
    id: "additionalDesc",
    heading: "Description",
  },
  {
    id: "additionalDuration",
    heading: "Duration/Quantity",
  },
  {
    id: "charge",
    heading: "Fixed Charge",
  },
  {
    id: "weekly",
    heading: "Weekly Charge",
  }
];

export const summaryCostColumns = [
  {
    id: "summaryConcept",
    heading: "",
  },
  {
    id: "summaryTotalCost",
    heading: "Total Cost",
  },
];
